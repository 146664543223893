
const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        ola: "Olá",
        ativo: "Ativo até",
        Bemvindoao: "Bem vindo a",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },
      companies: {
        nome: "Nome",
        telefone: "Telefone",
        plano: "Plano",
        status: "Status",
        Campanhas: "Campanhas",
        habilitada: "Habilitada",
        desabilitada: "Desabilitada",
        recorrência: "Recorrência",
        dataVencimento: "Data de Vencimento",
        mensal: "Mensal",
        criadaEm: "Criada em",
        Vencimento: "Vencimento",
        title: "Cadastrar Empresa",
        form: {
          name: "Nome da Empresa",
          plan: "Plano",
          token: "Token",
          submit: "Cadastrar",
          success: "Empresa criada com sucesso!",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
        token: "Token",
      },
      dashboard: {
        dataVencimiento: "Data Vencimiento",
        dataInicial: "Data Inicial",
        dataFinal: "Data Final",
        tipoDeFiltro: "Tipo de Filtro",
        filtroPorData: "Filtro por Data",
        filtroPorPeriodo: "Filtro por Período",
        seleecione: "Selecione o período desejado",
        filtrar: "Filtrar",
        pendentes: "Atd. Pendentes",
        acontecendo: "Atd. Acontecendo",
        realizados: "Atd. Realizados",
        leads: "Leads",
        atendimento: "T.M Atendimento",
        espera: "T.M Espera",
        periodo: "Período",
        nenhumSelecionado: "Nenhum selecionado",
        seleccionePeriodoDesajado: "Selecione o período desejado",
        nome: "Nome",
        Avaliações: "Avaliações",
        status: "Status (Atual)",
        charts: {
          perDay: {
            title: "Atendimentos hoje: ",
          },
        },
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          name: "Nome",
          default: "Padrão",
          sendIdQueue: "Fila",
          timeSendQueue: "Redirecionar para fila em X minutos",
          queueRedirection: "Redirecionamento de Fila",
          queueRedirectionDesc: "Selecione uma fila para os contatos que não possuem fila serem redirecionados",
          prompt: "Prompt",

        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os atendimentos relacionados serão perdidos.",
          importMessage: "Deseja importar todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      promptModal: {
        form: {
          name: "Nome",
          prompt: "Prompt",
          voice: "Voz",
          max_tokens: "Máximo de Tokens na resposta",
          temperature: "Temperatura",
          apikey: "API Key",
          max_messages: "Máximo de mensagens no Histórico",
          voiceKey: "Chave da API de Voz",
          voiceRegion: "Região de Voz",
        },
        success: "Prompt salvo com sucesso!",
        title: {
          add: "Adicionar Prompt",
          edit: "Editar Prompt",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      prompts: {
        title: "Prompts",
        table: {
          name: "Nome",
          queue: "Setor/Fila",
          max_tokens: "Máximo Tokens Resposta",
          actions: "Ações",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida!",
        },
        buttons: {
          add: "Adicionar Prompt",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      queueModal: {
        dadosFila: "Dados da fila",
        horariosAtendimento: "Horários de atendimento",
        success: "Queue saved successfully",
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
          complationMessage: "Mensagem de conclusão",
          outOfHoursMessage: "Mensagem de fora de expediente",
          ratingMessage: "Mensagem de avaliação",
          token: "Token",
          orderQueue: "Ordem da fila (Bot)",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
          whatsapp: "Conexão Padrão"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      scheduleModal: {
        title: {
          add: "Novo Agendamento",
          edit: "Editar Agendamento",
        },
        form: {
          body: "Mensagem",
          contact: "Contato",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Agendamento salvo com sucesso.",
      },
      tagModal: {
        title: {
          add: "Nova Tag",
          edit: "Editar Tag",
        },
        form: {
          name: "Nome",
          color: "Cor",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Tag salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop: "ARRASTE E SOLTE ARQUIVOS NO CAMPO ABAIXO",
          titleFileList: "Lista de arquivo(s)"
        },
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Filas",
      },
      tickets: {
        toasts: {
          deleted: "O atendimento que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Abertas" },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar atendimento e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldQueuePlaceholder: "Selecione uma fila",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum atendimento encontrado com esse status ou termo pesquisado",
        buttons: {
          accept: "Aceitar",
          closed: "Finalizar",
          reopen: "Reabrir"
        },
      },
      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          Atendimento: "Atendimento",
          Tarefas: "Tarefas",
          dashboard: "Dashboard",
          connections: "Conexões",
          tickets: "Atendimentos",
          quickMessages: "Respostas Rápidas",
          contacts: "Contatos",
          queues: "Filas & Chatbot",
          tags: "Tags",
          administration: "Administração",
          users: "Usuários",
          settings: "Configurações",
          helps: "Ajuda",
          messagesAPI: "API",
          schedules: "Agendamentos",
          campaigns: "Campanhas",
          annoucements: "Informativos",
          chats: "Chat Interno",
          financeiro: "Financeiro",
          files: "Lista de arquivos",
          prompts: "Open.Ai",
          listagem: "Listagem",
          listasContatos: "Listas de Contatos"
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
            notRegister: "Sem registro",
          },
        },
      },
      files: {
        title: "Lista de arquivos",
        table: {
          name: "Nome",
          contacts: "Contatos",
          actions: "Ação",
        },
        toasts: {
          deleted: "Lista excluída com sucesso!",
          deletedAll: "Todas as listas foram excluídas com sucesso!",
        },
        buttons: {
          add: "Adicionar",
          deleteAll: "Deletar Todos",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteAllTitle: "Deletar Todos",
          deleteMessage: "Tem certeza que deseja deletar esta lista?",
          deleteAllMessage: "Tem certeza que deseja deletar todas as listas?",
        },
      },
      messagesAPI: {
        title: "API",
        textMessage: {
          number: "Número",
          body: "Mensagem",
          token: "Token cadastrado",
        },
        mediaMessage: {
          number: "Número",
          body: "Nome do arquivo",
          media: "Arquivo",
          token: "Token cadastrado",
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      quickMessages: {
        title: "Respostas Rápidas",
        searchPlaceholder: "Pesquisar...",
        noAttachment: "Sem anexo",
        confirmationModal: {
          deleteTitle: "Exclusão",
          deleteMessage: "Esta ação é irreversível! Deseja prosseguir?",
        },
        buttons: {
          add: "Adicionar",
          attach: "Anexar Arquivo",
          cancel: "Cancelar",
          edit: "Editar",
        },
        toasts: {
          success: "Atalho adicionado com sucesso!",
          deleted: "Atalho removido com sucesso!",
        },
        dialog: {
          title: "Mensagem Rápida",
          shortcode: "Atalho",
          message: "Resposta",
          save: "Salvar",
          cancel: "Cancelar",
          geral: "Permitir editar",
          add: "Adicionar",
          edit: "Editar",
          visao: "Permitir visão",
        },
        table: {
          shortcode: "Atalho",
          message: "Mensagem",
          actions: "Ações",
          mediaName: "Nome do Arquivo",
          status: "Status",
        },
      },
      messageVariablesPicker: {
        label: "Variavéis disponíveis",
        vars: {
          contactFirstName: "Primeiro Nome",
          contactName: "Nome",
          greeting: "Saudação",
          protocolNumber: "Protocolo",
          date: "Data",
          hour: "Hora",
        },
      },
      contactLists: {
        title: "Listas de Contatos",
        table: {
          name: "Nome",
          contacts: "Contatos",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Lista",
        },
        dialog: {
          name: "Nome",
          company: "Empresa",
          okEdit: "Editar",
          okAdd: "Adicionar",
          add: "Adicionar",
          edit: "Editar",
          cancel: "Cancelar",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          deleted: "Registro excluído",
        },
      },
      contactListItems: {
        title: "Contatos",
        searchPlaceholder: "Pesquisa",
        buttons: {
          add: "Novo",
          lists: "Listas",
          import: "Importar",
        },
        dialog: {
          name: "Nome",
          number: "Número",
          whatsapp: "Whatsapp",
          email: "E-mail",
          okEdit: "Editar",
          okAdd: "Adicionar",
          add: "Adicionar",
          edit: "Editar",
          cancel: "Cancelar",
        },
        table: {
          name: "Nome",
          number: "Número",
          whatsapp: "Whatsapp",
          email: "E-mail",
          actions: "Ações",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
          importMessage: "Deseja importar os contatos desta planilha? ",
          importTitlte: "Importar",
        },
        toasts: {
          deleted: "Registro excluído",
        },
      },
      campaigns: {
        title: "Campanhas",
        searchPlaceholder: "Pesquisa",
        short: "Too short!",
        long: "Too long!",
        required: "Required",
        desabilitada: "Desabilitada",
        habilitada: "Habilitada",
        buttons: {
          add: "Nova Campanha",
          contactLists: "Listas de Contatos",
        },
        table: {
          name: "Nome",
          whatsapp: "Conexão",
          contactList: "Lista de Contatos",
          status: "Status",
          scheduledAt: "Agendamento",
          completedAt: "Concluída",
          confirmation: "Confirmação",
          actions: "Ações",
        },
        dialog: {
          new: "Nova Campanha",
          update: "Editar Campanha",
          readonly: "Apenas Visualização",
          form: {
            name: "Nome",
            message1: "Mensagem 1",
            message2: "Mensagem 2",
            message3: "Mensagem 3",
            message4: "Mensagem 4",
            message5: "Mensagem 5",
            confirmationMessage1: "Mensagem de Confirmação 1",
            confirmationMessage2: "Mensagem de Confirmação 2",
            confirmationMessage3: "Mensagem de Confirmação 3",
            confirmationMessage4: "Mensagem de Confirmação 4",
            confirmationMessage5: "Mensagem de Confirmação 5",
            messagePlaceholder: "Conteúdo da mensagem",
            whatsapp: "Conexão",
            status: "Status",
            scheduledAt: "Agendamento",
            confirmation: "Confirmação",
            contactList: "Lista de Contato",
            tagList: "Lista de Tags",
            fileList: "Lista de Arquivos"
          },
          buttons: {
            add: "Adicionar",
            edit: "Atualizar",
            okadd: "Ok",
            cancel: "Cancelar Disparos",
            restart: "Reiniciar Disparos",
            close: "Fechar",
            attach: "Anexar Arquivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          success: "Operação realizada com sucesso",
          cancel: "Campanha cancelada",
          restart: "Campanha reiniciada",
          deleted: "Registro excluído",
        },
      },
      announcements: {
        active: 'Ativo',
        inactive: 'Inativo',
        title: "Informativos",
        searchPlaceholder: "Pesquisa",
        buttons: {
          add: "Novo Informativo",
          contactLists: "Listas de Informativos",
        },
        table: {
          priority: "Prioridade",
          title: "Title",
          text: "Texto",
          mediaName: "Arquivo",
          status: "Status",
          actions: "Ações",
        },
        dialog: {
          edit: "Edição de Informativo",
          add: "Novo Informativo",
          update: "Editar Informativo",
          readonly: "Apenas Visualização",
          form: {
            priority: "Prioridade",
            title: "Title",
            text: "Texto",
            mediaPath: "Arquivo",
            status: "Status",
          },
          buttons: {
            add: "Adicionar",
            edit: "Atualizar",
            okadd: "Ok",
            cancel: "Cancelar",
            close: "Fechar",
            attach: "Anexar Arquivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          success: "Operação realizada com sucesso",
          deleted: "Registro excluído",
        },
      },
      campaignsConfig: {
        title: "Configurações de Campanhas",
      },
      queues: {
        title: "Filas & Chatbot",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
          orderQueue: "Ordenação da fila (bot)",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os atendimentos dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os atendimento abertos deste usuário serão movidos para a fila.",
        },
      },
      helps: {
        title: "Central de Ajuda",
      },
      schedules: {
        title: "Agendamentos",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir este Agendamento?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          contact: "Contato",
          body: "Mensagem",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
          status: "Status",
          actions: "Ações",
        },
        buttons: {
          add: "Novo Agendamento",
        },
        toasts: {
          deleted: "Agendamento excluído com sucesso.",
        },
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir esta Tag?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          name: "Nome",
          color: "Cor",
          tickets: "Registros Tagdos",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Tag",
        },
        toasts: {
          deleted: "Tag excluído com sucesso.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Digite uma mensagem",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      fileModal: {
        title: {
          add: "Adicionar lista de arquivos",
          edit: "Editar lista de arquivos",
        },
        buttons: {
          okAdd: "Salvar",
          okEdit: "Editar",
          cancel: "Cancelar",
          fileOptions: "Adicionar arquivo",
        },
        form: {
          name: "Nome da lista de arquivos",
          message: "Detalhes da lista",
          fileOptions: "Lista de arquivos",
          extraName: "Mensagem para enviar com arquivo",
          extraValue: "Valor da opção",
        },
        success: "Lista de arquivos salva com sucesso!",
      },
      ticketOptionsMenu: {
        schedule: "Agendamento",
        delete: "Deletar",
        transfer: "Transferir",
        registerAppointment: "Observações do Contato",
        appointmentsModal: {
          title: "Observações do Contato",
          textarea: "Observação",
          placeholder: "Insira aqui a informação que deseja registrar",
        },
        confirmationModal: {
          title: "Deletar o ticket do contato",
          message:
            "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      financeiro: {
        Detalhes: "Detalhes",
        Valor: "Valor",
        DataVenc: "Data Venc",
        Status: "Status",
        Ação: "Ação",
        PAGAR: "PAGAR",
        PAGO: "PAGO",

      },
      messagesAPI: {
        messagesEnvioDoc: "Documentação para envio de mensagens",
        metodosEnvio: "Métodos de Envio",
        mensagenText: "Mensagens de Texto",
        mensagenMedia: "Mensagens de Mídia",
        Instruções: "Instruções",
        ObservaçõesImportantes: "Observações importantes",
        ObservaçõesImportantes1: "Antes de enviar mensagens, é necessário o cadastro do token vinculado à conexão que enviará as mensagens.",
        ObservaçõesImportantes2: "Para realizar o cadastro acesse o menu 'Conexões', clique no botão editar da conexão e insira o token no devido campo.",
        ObservaçõesImportantes3: "O número para envio não deve ter mascara ou caracteres especiais e deve ser composto por:",
        codigoPais: "Código do país",
        ddd: "DDD",
        numero: "Número",
        mensagenText: "Mensagens de Texto",
        ObservaçõesImportantes4: "Seguem abaixo a lista de informações necessárias para envio das mensagens de texto:",
        Endpoint: "Endpoint",
        metodo: "Método",
        headers: "Headers",
        body: "Body",
        teste: "Teste de Envio",
        mensagenMedia: "Mensagens de Mídia",
        ObservaçõesImportantes4: "Seguem abaixo a lista de informações necessárias para envio das mensagens de texto",
        FormData: "FormData",
        number: "number",
        medias: "medias",
        arquivo: "arquivo",
        testeEnvio: "arquivo",
        tokenCadastrado: "token cadastrado",
      },
      kanban: {
        emAberto: "Em aberto",
      },
      tarefa: {
        tarefa: "Nova tarefa",
      },
      chatInternal: {
        nova: "Nova",
        conversa: "Conversa",
        filtroUsers: "Filtro por Users",
        excluirConversa: "Excluir Conversa",
        alertExcluirConversa: "Esta ação não pode ser revertida, confirmar?"
      },
      // dashboard: {
      //   dataVencimiento: "Data Vencimiento",
      //   dataInicial: "Data Inicial",
      //   dataFinal: "Data Final",
      //   tipoDeFiltro: "Tipo de Filtro",
      //   filtroPorData: "Filtro por Data",
      //   filtroPorPeriodo: "Filtro por Período",
      //   seleecione: "Selecione o período desejado",
      //   filtrar: "Filtrar",
      //   pendentes: "Atd. Pendentes",
      //   acontecendo: "Atd. Acontecendo",
      //   realizados: "Atd. Realizados",
      //   leads: "Leads",
      //   atendimento: "T.M Atendimento",
      //   espera: "T.M Espera",
      //   periodo: "Período",
      //   nenhumSelecionado: "Nenhum selecionado",
      //   seleccionePeriodoDesajado: "Selecione o período desejado",
      //   nome: "Nome",
      //   Avaliações: "Avaliações",
      //   status: "Status (Atual)",

      // },

      Opções: {
        atualizando: "Actualizando",
        opções: "Opciones",
        empresas: "Empresas",
        planos: "Planes",
        ajuda: "Ayuda",
        exito: "Operación actualizada con éxito.",
        avaliações: "Avaliações",
        habilitadas: "Habilitadas",
        desabilitadas: "Desabilitadas",
        desabilitado: "Desabilitado",
        habilitado: "Habilitado",
        gerenciamiento: "Gerenciamento de Expediente",
        gerenciamientofila: "Gerenciamento Por Fila",
        ignorarMesageGrupos: "Ignorar Mensagens de Grupos",
        desactivado: "Desativado",
        activado: "Ativado",
        chamada: "Aceitar Chamada",
        nãoAceita: "Não Aceitar",
        aceitar: "Aceitar",
        tipoChatbot: "Tipo Chatbot",
        texto: "Texto",
        Enviarsaudaçãoaoaceitarticket: "Enviar saudação ao aceitar o ticket",
        enviarMensagemTransferenciadeFilaAgente: "Enviar mensagem de transferencia de Fila/agente",
        integrqacoes: "INTEGRAÇÕES",
        tituloNoDefinido: "Título não definido"
      },
      modals: {
        muitoCorto: "Muito curto!",
        muitoLongo: "Muito longo!",
        obrigatorio: "Obrigatório",
        descrevaTreinamentoDoIA: "Descreva o treinamento para Inteligência Artificial",
        informeoModoVoz: "Informe o modo para Voz",
        informeMaxTokens: "Informe o número máximo de tokens",
        informeTemperatura: "Informe a temperatura",
        informeApiKey: "Informe a API Key",
        informeFila: "Informe a fila",
        informeMaxMensagnes: "Informe o número máximo de mensagens",
        informeSetor: "Informe o setor",
      },
      checkoutFormModel: {

        label: {
          nomeCompleto: "Nome completo*",
          lastName: "Last name*",
          endereco: "Endereço*",
          cidade: "Cidade*",
          estado: "Estado*",
          pais: "País*",
          useAddressForPaymentDetails: "Use this address for payment details",
          useInvoiceId: "Use this invoiceId*",
          NameCard: "Name on card*",
          cardNumber: "Card number*",
          ExpireDate: "Expiry date*",
        },
        requiredErrorMsg: {
          nome: "O nome é obrigatório.",
          lastName: "Last name is required.",
          endreco: "O endereço é obrigatório.",
          cidade: "Cidade é obrigatória",
          state: "State é obrigatória",
          zipcode: "CEP é obrigatório",
          pais: "País é obrigatório",
          NameCard: "Name on card is required.",
          cardNumber: "Card number is required.",
          ExpireDate: "Expiry date is required.",
          cvv: "CVV is required.",
        },
        invalidErrorMsg: {
          zipcode: "Formato de CEP inválido",
          cardNumber: "Card number is not valid (e.g. 4111111111111)",
          ExpireDate: "Expiry date is not valid",
          cvv: "CVV is invalid (e.g. 357)",
        }
      },
      planos: {
        nome: "Nome",
        valor: "Valor",
        usuarios: "Usuários",
        conexões: "Conexões",
        filas: "Filas",
        limpar: "Limpar",
        excluir: "Excluir"
      },
      ayuda: {
        titulo: "Título",
        codigoVideo: "Código do Video",
        descricao: "Descrição",
        limpiar: "Limpar",
        excluir: "Excluir",
        errCargarListas: "Não foi possível carregar a lista de registros",
        sucessoExitoso: "Operação realizada com sucesso!",
        errNoPosibleSucesso: "Não foi possível realizar a operação. Verifique se já existe uma helpo com o mesmo nome ou se os campos foram preenchidos corretamente"
      },

      CheckoutSuccess: {
        toatal: "TOTAL",
        copiado: "Copiado",
        copiarQR: "Copiar código QR",
        finalizar: "Para finalizar, basta realizar o pagamento escaneando ou colando o código Pix acima :"
      },

      contacnotes: {
        excluirrEgistro: "Excluir Registro",
        cancelar: "Cancelar",
        obsevacionSucesso: "Observação adicionada com sucesso!",
        obsevacionExcluida: "Observação excluída com sucesso!",
      },
      etc: {
        obrigatorio: "Obrigatório",
        adicionarVariable: "Adicionar Variável",
        exportarContato: "EXPORTAR CONTATOS ",
        salvarconfiguracioes: "Salvar Configurações",
        salvarParaRegistrar: "Clique em salvar para registar as alterações",
        salvarRegistrarAlteracioes: "Clique em salvar para registar as alterações",
        helperText: "Utilize variáveis como {nome}, {numero}, {email} ou defina variáveis personalziadas.",
        seleccioneFila: "Selecione uma fila",
        seleccioneConexao: "Selecione uma Conexão",
        assinaturaSucesso: "Assinatura realizada com sucesso!, aguardando a realização do pagamento",
        voltar: "VOLTAR",
        licencarenovada: "Sua licença foi renovada até",
        errComppanie: "Não foi possível realizar a operação. Verifique se já existe uma empresa com o mesmo nome ou se os campos foram preenchidos corretamente",
        errComppanieOperacao: "Não foi possível realizar a operação",
        naoCargarListas: "Não foi possível carregar a lista de registros",
        operacaoSucesso: "Operação realizada com succeso!",
        errOperacao: "Não foi possível realizar a operação",
        noFoiPosbleOperacao: "Não foi possível realizar a operação. Verifique se já existe uma plano com o mesmo nome ou se os campos foram preenchidos corretamente",
        acessoNoaPermitido: "Acesso não permitido",
        ticketDeleted: "Ticket deleted sucessfully.",
        errorOccurred: "An error occurred.",
        venceAsinatura: "Sua assinatura vence em",
        oppdVenceAsinatrua: "Opps! Sua assinatura vence em ",
        oppdVenceAsinatrua2: "dias. Por favor, renove sua assinatura para continuar usando o sistema.",
        contaOutroComputador: "Sua conta foi acessada em outro computador.",
        redireccion: "Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando.",
        parametrizeFiltro: "Parametrize o filtro",
        ticketRemovido: "Ticket Tag Removido!",
        ticketAdicionado: "Ticket Tag Adicionado com Sucesso!",
        mensagemExito: "Mensagem enviada com sucesso!",
        hoarariosAtualizados: "Horários atualizados com sucesso.",
        atalho: "Atalho",
        mensagem: "Mensagem",
        errEnvio: "Erro de Envio",
        ações: "Ações",
        mensagemPeloContato: "Essa mensagem foi apagada pelo contato",
        nenhumRegistro: "Ningún registro.",
        nao: "Não",
        sim: "Sim",
        tags: "Tags",
        atalhos: "Atalhos",
        conteudo: "Conteúdo",
        porFavorPreenchaTitulo: "Por favor, completa el título de la conversación.",
        SlecionePeloMenosUsuario: "Por favor, selecciona al menos un usuario.",
        Gerência:"Gerência",

      },

      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um tíquete aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de uma fila.",
      },
    },
  },
};

export { messages };