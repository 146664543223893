import { i18n } from "../../../translate/i18n";

export default {
  formId: 'checkoutForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: i18n.t("checkoutFormModel.label.nomeCompleto"),
      requiredErrorMsg: i18n.t("checkoutFormModel.requiredErrorMsg.nome"),
    },
    lastName: {
      name: 'lastName',
      label: i18n.t("checkoutFormModel.label.lastName"),
      requiredErrorMsg: i18n.t("checkoutFormModel.requiredErrorMsg.lastName"),
    },
    address1: {
      name: 'address2',
      label: i18n.t("checkoutFormModel.label.endereco"),
      requiredErrorMsg: i18n.t("checkoutFormModel.requiredErrorMsg.endreco"),
    },

    city: {
      name: 'city',
      label: i18n.t("checkoutFormModel.label.cidade"),
      requiredErrorMsg: i18n.t("checkoutFormModel.requiredErrorMsg.cidade"),
    },
    state: {
      name: 'state',
      label: i18n.t("checkoutFormModel.label.estado"),
      requiredErrorMsg: i18n.t("checkoutFormModel.requiredErrorMsg.state"),
    },
    zipcode: {
      name: 'zipcode',
      label: 'CEP*',
      requiredErrorMsg: i18n.t("checkoutFormModel.requiredErrorMsg.zipcode"),
      invalidErrorMsg: i18n.t("checkoutFormModel.invalidErrorMsg.zipcode"),
    },
    country: {
      name: 'country',
      label: i18n.t("checkoutFormModel.label.pais"),
      requiredErrorMsg: i18n.t("checkoutFormModel.requiredErrorMsg.pais"),
    },
    useAddressForPaymentDetails: {
      name: 'useAddressForPaymentDetails',
      label: i18n.t("checkoutFormModel.label.useAddressForPaymentDetails"),
    },
    invoiceId: {
      name: 'invoiceId',
      label: i18n.t("checkoutFormModel.label.useInvoiceId"),
    },
    nameOnCard: {
      name: 'nameOnCard',
      label: i18n.t("checkoutFormModel.label.NameCard"),
      requiredErrorMsg: i18n.t("checkoutFormModel.requiredErrorMsg.NameCard"),
    },
    cardNumber: {
      name: 'cardNumber',
      label: i18n.t("checkoutFormModel.label.cardNumber"),
      requiredErrorMsg: i18n.t("checkoutFormModel.requiredErrorMsg.cardNumber"),
      invalidErrorMsg: i18n.t("checkoutFormModel.invalidErrorMsg.cardNumber"),
    },
    expiryDate: {
      name: 'expiryDate',
      label: i18n.t("checkoutFormModel.label.ExpireDate"),
      requiredErrorMsg: i18n.t("checkoutFormModel.requiredErrorMsg.ExpireDate"),
      invalidErrorMsg: i18n.t("checkoutFormModel.invalidErrorMsg.ExpireDate"),
    },
    cvv: {
      name: 'cvv',
      label: 'CVV*',
      requiredErrorMsg: i18n.t("checkoutFormModel.requiredErrorMsg.cvv"),
      invalidErrorMsg: i18n.t("checkoutFormModel.invalidErrorMsg.cvv"),
    }
  }
};