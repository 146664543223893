const messages = {
	es: {
		translations: {
			signup: {
				title: "Registro",
				ola: "Hola",
				ativo: "Activo hasta",
				Bemvindoa: "Bienvenido a",
				toasts: {
					success: "¡Usuario creado exitosamente! ¡¡¡Inicia sesión!!!",
					fail: "Error al crear usuario. Consulta los datos proporcionados.",
				},
				form: {
					name: "Nombre",
					email: "Correo electrónico",
					password: "Contraseña",
				},
				buttons: {
					submit: "Registro",
					login: "¿Ya tienes una cuenta? ¡Entre!",
				},
			},
			login: {
				title: "Login",
				form: {
					email: "Correo electrónico",
					password: "Contraseña",
				},
				buttons: {
					submit: "Entrar",
					register: "¿No tienes una cuenta? ¡Regístrate!",
				},
			},
			companies: {
				nome: "Nombre",
				telefone: "Telefono",
				plano: "Plan",
				status: "Estado",
				Campanhas: "Campañas",
				habilitada: "Habilitada",
				desabilitada: "Desabilitada",
				recorrência: "Recurrencia",
				dataVencimento: "Fecha de Vencimiento",
				mensal: "MENSUAL",
				status: "Estado",
				criadaEm: "Creado en",
				Vencimento: "Vencimiento",
				title: "Registrar Empresa",
				form: {
					name: "Nombre de empresa",
					plan: "Departamento",
					token: "Token",
					submit: "Registro",
					success: "¡Empresa creada con éxito!",
				},
			},
			auth: {
				toasts: {
					success: "¡Inicia sesión exitosamente!",
				},
				token: "Token",
			},
			dashboard: {
				dataVencimiento: "Fecha de Vencimiento",
				dataInicial: "Fecha Inicial",
				dataFinal: "Fecha Final",
				tipoDeFiltro: "Tipo de Filtro",
				filtroPorData: "Filtro por Fecha",
				filtroPorPeriodo: "Filtro por Período",
				seleecione: "Seleccione el período deseado",
				filtrar: "Filtrar",
				pendentes: "Atd. Pendientes",
				acontecendo: "Atd. En Curso",
				realizados: "Atd. Realizados",
				leads: "Leads",
				atendimento: "T.M Atención",
				espera: "T.M Espera",
				periodo: "Período",
				nenhumSelecionado: "Ninguno seleccionado",
				seleccionePeriodoDesajado: "Seleccione el período deseado",
				nome: "Nombre",
				Avaliações: "Evaluaciones",
				status: "Estado (Actual)",
				charts: {
					perDay: {
						title: "Servicios hoy: ",
					},
				},
			},
			connections: {
				title: "Conexiones",
				toasts: {
					deleted: "¡La conexión de WhatsApp se eliminó con éxito!",
				},
				confirmationModal: {
					deleteTitle: "Borrar",
					deleteMessage: "¿Está seguro? Esta acción no se puede revertir.",
					disconnectTitle: "Desconectar",
					disconnectMessage:
						"¿Está seguro? Deberá escanear el código QR nuevamente.",
				},
				buttons: {
					add: "Agregar whatsapp",
					disconnect: "Desconectar",
					tryAgain: "Intentar otra vez",
					qrcode: "CÓDIGO QR",
					newQr: "Nuevo CÓDIGO QR",
					connecting: "Conectando",
				},
				toolTips: {
					disconnected: {
						title: "No se pudo iniciar sesión en WhatsApp",
						content:
							"Asegúrese de que su teléfono esté conectado a Internet e inténtelo nuevamente o solicite un nuevo código QR",
					},
					qrcode: {
						title: "Esperando que se lea el código QR",
						content:
							"Haz clic en el botón 'CÓDIGO QR' y escanea el Código QR con tu celular para iniciar la sesión",
					},
					connected: {
						title: "¡Conexión establecida!",
					},
					timeout: {
						title: "Conexión celular perdida",
						content:
							"Asegúrese de que su teléfono esté conectado a Internet y WhatsApp esté abierto, o haga clic en el botón 'Desconectar' para obtener un nuevo código QR.",
					},
				},
				table: {
					name: "Nombre",
					status: "Estado",
					lastUpdate: "Última actualización",
					default: "Estándar",
					actions: "Acciones",
					session: "Sesión",
				},
			},
			whatsappModal: {
				title: {
					add: "Agregar whatsapp",
					edit: "Editar WhatsApp",
				},
				form: {
					name: "Nombre",
					default: "Estándar",
					sendIdQueue: "Fila",
					timeSendQueue: "Redirigir a la fila en X minutos",
					queueRedirection: "Redirección de fila",
					queueRedirectionDesc: "Seleccione una fila para los contactos que no tienen una fila para ser redirigidos",
					prompt: "Prompt",

				},
				buttons: {
					okAdd: "Agregar",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "¡WhatsApp guardado con éxito!",
			},
			qrCode: {
				message: "Escanee el código QR con su teléfono",
			},
			contacts: {
				title: "Contactos",
				toasts: {
					deleted: "¡Contacto eliminado con éxito!",
				},
				searchPlaceholder: "Buscar...",
				confirmationModal: {
					deleteTitle: "Eliminar",
					importTitlte: "Importar contactos",
					deleteMessage:
						"¿Estás seguro de que deseas eliminar este contacto? Se perderán todos los servicios relacionados.",
					importMessage: "¿Quieres importar todos los contactos desde tu teléfono?",
				},
				buttons: {
					import: "Importar Contactos",
					add: "Agregar Contacto",
				},
				table: {
					name: "Nombre",
					whatsapp: "WhatsApp",
					email: "Email",
					actions: "Acciones",
				},
			},
			promptModal: {
				form: {
					name: "Nombre",
					prompt: "Prompt",
					voice: "Voz",
					max_tokens: "Tokens máximos en respuesta",
					temperature: "Temperatura",
					apikey: "API Key",
					max_messages: "Mensajes máximos en el historial",
					voiceKey: "Clave API de voz",
					voiceRegion: "Región de voz",
				},
				success: "¡Aviso guardado exitosamente!",
				title: {
					add: "Agregar mensaje",
					edit: "Editar mensaje",
				},
				buttons: {
					okAdd: "Agregar",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
			},
			prompts: {
				title: "Prompts",
				table: {
					name: "Nombre",
					queue: "Sector/Fila",
					max_tokens: "Maximo de tokens por respuesta",
					actions: "Acciones",
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage: "¿Está seguro? ¡Esta acción no se puede revertir!",
				},
				buttons: {
					add: "Agregar Prompt",
				},
			},
			contactModal: {
				title: {
					add: "Añadir contacto",
					edit: "Editar contato",
				},
				form: {
					mainInfo: "Datos de contacto",
					extraInfo: "Información adicional",
					name: "Nombre",
					number: "Número de Whatsapp",
					email: "Email",
					extraName: "Nombre del campo",
					extraValue: "Valor",
				},
				buttons: {
					addExtraInfo: "Agregar información",
					okAdd: "para agregar",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "Contacto guardado exitosamente.",
			},
			queueModal: {
				success: "Cola guardada con éxito",
				dadosFila: "Datos de la fila",
				horariosAtendimento: "Horarios de atención",
				title: {
					add: "Adicionar fila",
					edit: "Editar fila",
				},
				form: {
					name: "Nombre",
					color: "Color",
					greetingMessage: "Mensaje de saludo",
					complationMessage: "Mensaje de finalización",
					outOfHoursMessage: "Mensaje fuera de horario",
					ratingMessage: "Revisar mensaje",
					token: "Token",
					orderQueue: "Orden de fila (Bot)",
				},
				buttons: {
					okAdd: "Agregar",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
			},
			userModal: {
				title: {
					add: "Agregar usuário",
					edit: "Editar usuário",
				},
				form: {
					name: "Nombre",
					email: "Email",
					password: "Contraseña",
					profile: "Perfil",
					whatsapp: "Conexión estándar"
				},
				buttons: {
					okAdd: "Agregar",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "Usuario guardado exitosamente.",
			},
			scheduleModal: {
				title: {
					add: "Nuevo horario",
					edit: "Editar horario",
				},
				form: {
					body: "Mensaje",
					contact: "Contacto",
					sendAt: "Fecha programada",
					sentAt: "Fecha de envío",
				},
				buttons: {
					okAdd: "Agregar",
					okEdit: "Editar",
					cancel: "Cancelar",
				},
				success: "Cita guardada exitosamente.",
			},
			tagModal: {
				title: {
					add: "Nuevo etiqueta",
					edit: "Editar etiqueta",
				},
				form: {
					name: "Nombre",
					color: "Color",
				},
				buttons: {
					okAdd: "Agregar",
					okEdit: "Editar",
					cancel: "Cancelar",
				},
				success: "Etiqueta guardada exitosamente.",
			},
			chat: {
				noTicketMessage: "Seleccione un ticket para comenzar a chatear.",
			},
			uploads: {
				titles: {
					titleUploadMsgDragDrop: "ARRASTRA Y SUELTA ARCHIVOS EN EL CAMPO A CONTINUACIÓN",
					titleFileList: "Lista de arquivo(s)"
				},
			},
			ticketsManager: {
				buttons: {
					newTicket: "Nuevo",
				},
			},
			ticketsQueueSelect: {
				placeholder: "Filas",
			},
			tickets: {
				toasts: {
					deleted: "El servicio en el que estabas ha sido eliminado.",
				},
				notification: {
					message: "Mensaje de",
				},
				tabs: {
					open: { title: "Abierto" },
					closed: { title: "Resuelto" },
					search: { title: "Buscar" },
				},
				search: {
					placeholder: "Servicio de búsqueda y mensajes.",
				},
				buttons: {
					showAll: "Todos",
				},
			},
			transferTicketModal: {
				textioOpcion: "Escriba el texto de la opción",
				title: "Transferir Ticket",
				fieldLabel: "Digite para buscar usuários",
				fieldQueueLabel: "Transferir para fila",
				fieldQueuePlaceholder: "Selecione una fila",
				noOptions: "No se encontraron usuarios con ese nombre",
				buttons: {
					ok: "Transferir",
					cancel: "Cancelar",
				},
			},
			ticketsList: {
				pendingHeader: "Espera",
				assignedHeader: "Servicio",
				noTicketsTitle: "Nada aqui!",
				noTicketsMessage:
					"No se encontró ningún servicio con este estado o término de búsqueda",
				buttons: {
					accept: "aceptar",
					closed: "Finalizar",
					reopen: "Reabrir"
				},
			},
			newTicketModal: {
				title: "Crear Ticket",
				fieldLabel: "Escriba para buscar contacto",
				add: "Agregar",
				buttons: {
					ok: "Guardar",
					cancel: "Cancelar",
				},
			},
			mainDrawer: {
				listItems: {
					Atendimento: "Atención",
					Tarefas: "Tareas",
					dashboard: "Dashboard",
					connections: "Conexiones",
					tickets: "Servicios",
					quickMessages: "Respuestas Rápidas",
					contacts: "Contactos",
					queues: "Filas & Chatbot",
					tags: "Etiquetas",
					administration: "Administración",
					users: "Usuários",
					settings: "Configuraciones",
					helps: "Ayuda",
					messagesAPI: "API",
					schedules: "Citas",
					campaigns: "Campañas",
					annoucements: "Informativos",
					chats: "Chat Interno",
					financeiro: "Financiero",
					files: "Lista de archivos",
					prompts: "Open.Ai",
					listagem: "Listado",
					listasContatos: "Listas de Contactos"
				},
				appBar: {
					user: {
						profile: "Perfil",
						logout: "Salir",
						notRegister: "Sin mensajes "
					},
				},
			},

			files: {
				title: "Lista de archivos",
				table: {
					name: "Nombre",
					contacts: "Contactos",
					actions: "Acciones",
				},
				toasts: {
					deleted: "¡Lista eliminada exitosamente!",
					deletedAll: "Todas as listas foram excluídas com sucesso!",
				},
				buttons: {
					add: "Agregar",
					deleteAll: "Eliminar Todos",
				},
				confirmationModal: {
					deleteTitle: "Eliminar",
					deleteAllTitle: "Eliminar Todos",
					deleteMessage: "¿Estás seguro de que deseas eliminar esta lista?",
					deleteAllMessage: "¿Está seguro de que desea eliminar todas las listas?",
				},
			},
			messagesAPI: {
				title: "API",
				textMessage: {
					number: "Número",
					body: "Mensaje",
					token: "Token registrado",
				},
				mediaMessage: {
					number: "Número",
					body: "Nombre doe archivo",
					media: "Archivo",
					token: "Token registrado",
				},
			},
			notifications: {
				noTickets: "Sin notificaciones.",
			},
			quickMessages: {
				title: "Respuestas rápidas",
				searchPlaceholder: "Para buscar...",
				noAttachment: "Sin archivos adjuntos",
				confirmationModal: {
					deleteTitle: "Exclusión",
					deleteMessage: "¡Esta acción es irreversible! ¿Desea continuar?",
				},
				buttons: {
					add: "Agregar",
					attach: "Adjuntar archivo",
					cancel: "Cancelar",
					edit: "Editar",
				},
				toasts: {
					success: "¡Atajo agregado exitosamente!",
					deleted: "¡El acceso directo se eliminó correctamente!",
				},
				dialog: {
					title: "Mensaje rápido",
					shortcode: "Atajo",
					message: "Respuesta",
					save: "Guardar",
					cancel: "Cancelar",
					geral: "Permitir editar",
					add: "Agregar",
					edit: "Editar",
					visao: "Permitir vista",
				},
				table: {
					shortcode: "Atajo",
					message: "Mensaje",
					actions: "Acciones",
					mediaName: "Nombre del archivo",
					status: "Estado",
				},
			},
			messageVariablesPicker: {
				label: "Variables disponibles",
				vars: {
					contactFirstName: "Primer Nombre",
					contactName: "Nombre",
					greeting: "Saludo",
					protocolNumber: "Protocolo",
					date: "Data",
					hour: "Hora",
				},
			},
			contactLists: {
				title: "Listas de Contactos",
				table: {
					name: "Nombre",
					contacts: "Contactos",
					actions: "Acciones",
				},
				buttons: {
					add: "Nueva Lista",
				},
				dialog: {
					name: "Nombre",
					company: "Empresa",
					okEdit: "Editar",
					okAdd: "Agregar",
					add: "Agregar",
					edit: "Editar",
					cancel: "Cancelar",
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage: "Esta acción no se puede revertir.",
				},
				toasts: {
					deleted: "Registro eliminado",
				},
			},
			contactListItems: {
				title: "Contactos",
				searchPlaceholder: "Buscar",
				buttons: {
					add: "Nuevo",
					lists: "Listas",
					import: "Importar",
				},
				dialog: {
					name: "Nombre",
					number: "Número",
					whatsapp: "Whatsapp",
					email: "E-mail",
					okEdit: "Editar",
					okAdd: "Agregar",
					add: "Agregar",
					edit: "Editar",
					cancel: "Cancelar",
				},
				table: {
					name: "Nombre",
					number: "Número",
					whatsapp: "Whatsapp",
					email: "E-mail",
					actions: "Acciones",
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage: "Esta acción no se puede revertir.",
					importMessage: "¿Quieres importar contactos desde esta hoja de cálculo?",
					importTitlte: "Importar",
				},
				toasts: {
					deleted: "Registro excluído",
				},
			},
			campaigns: {
				title: "Campañas",
				searchPlaceholder: "Buscar",
				short: "¡Demasiado corto!",
				long: "¡Demasiado largo!",
				required: "Requerido",
				desabilitada: "Desabilitada",
				habilitada: "Habilitada",
				buttons: {
					add: "Nueva campaña",
					contactLists: "Listas de Contactos",
				},
				table: {
					name: "Nombre",
					whatsapp: "Conexión",
					contactList: "Lista de Contactos",
					status: "Estado",
					scheduledAt: "Cita",
					completedAt: "Concluída",
					confirmation: "Confirmación",
					actions: "Acciones",
				},
				dialog: {
					new: "Nueva campaña",
					update: "Editar Campaña",
					readonly: "Solo Lectura",
					form: {
						name: "Nombre",
						message1: "Mensaje 1",
						message2: "Mensaje 2",
						message3: "Mensaje 3",
						message4: "Mensaje 4",
						message5: "Mensaje 5",
						confirmationMessage1: "Mensaje de Confirmación 1",
						confirmationMessage2: "Mensaje de Confirmación 2",
						confirmationMessage3: "Mensaje de Confirmación 3",
						confirmationMessage4: "Mensaje de Confirmación 4",
						confirmationMessage5: "Mensaje de Confirmación 5",
						messagePlaceholder: "Contenido del mensaje",
						whatsapp: "Conexión",
						status: "Estado",
						scheduledAt: "Cita",
						confirmation: "Confirmación",
						contactList: "Lista de Contacto",
						tagList: "Lista de Tags",
						fileList: "Lista de Archivos"
					},
					buttons: {
						add: "Agregar",
						edit: "Editar",
						okadd: "Ok",
						cancel: "Cancelar Disparos",
						restart: "Reiniciar Disparos",
						close: "Cerar",
						attach: "Adjuntar archivo",
					},
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage: "Esta acción no se puede revertir.",
				},
				toasts: {
					success: "Operación realizada con éxito",
					cancel: "Campaña cancelada",
					restart: "Campaña reiniciada",
					deleted: "Registro excluído",
				},
			},
			announcements: {
				active: 'Activo',
				inactive: 'Inactivo',
				title: "Informativos",
				searchPlaceholder: "Buscar",
				buttons: {
					add: "Nuevo Informativo",
					contactLists: "Listas de Informativos",
				},
				table: {
					priority: "Prioridad",
					title: "Título",
					text: "Texto",
					mediaName: "Archivo",
					status: "Estado",
					actions: "Acciones",
				},
				//estoy aqui
				dialog: {
					edit: "Edición del Informativo",
					add: "Nuevo Informativo",
					update: "Editar Informativo",
					readonly: "Solo Lectura",
					form: {
						priority: "Prioridad",
						title: "Título",
						text: "Texto",
						mediaPath: "Archivo",
						status: "Estado",
					},
					buttons: {
						add: "Agregar",
						edit: "Editar",
						okadd: "Ok",
						cancel: "Cancelar",
						close: "Cerrar",
						attach: "Adjuntar archivo",
					},
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage: "Esta acción no se puede revertir.",
				},
				toasts: {
					success: "Operación realizada con éxito",
					deleted: "Registro eliminado",
				},
			},
			campaignsConfig: {
				title: "Configuración de campaña",
			},
			queues: {
				title: "Filas & Chatbot",
				table: {
					name: "Nombre",
					color: "Color",
					greeting: "Mensaje de saludo",
					actions: "Acciones",
					orderQueue: "Ordenación de filas (bot)",
				},
				buttons: {
					add: "Agregar fila",
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage:
						"¿Está seguro? ¡Esta acción no se puede revertir! Los servicios en esta cola seguirán existiendo, pero ya no tendrán ninguna cola asignada.",
				},
			},
			queueSelect: {
				inputLabel: "Filas",
			},
			users: {
				title: "Usuários",
				table: {
					name: "Nombre",
					email: "Email",
					profile: "Perfil",
					actions: "Acciones",
				},
				buttons: {
					add: "Agregar usuário",
				},
				toasts: {
					deleted: "Usuario eliminado exitosamente.",
				},
				confirmationModal: {
					deleteTitle: "Borrar",
					deleteMessage:
						"Todos los datos del usuario se perderán. Las llamadas abiertas de este usuario se moverán a la fila.",
				},
			},
			helps: {
				title: "Centro de ayuda",
			},
			schedules: {
				title: "Citas",
				confirmationModal: {
					deleteTitle: "¿Está seguro de que desea eliminar este cita?",
					deleteMessage: "Esta acción no se puede revertir.",
				},
				table: {
					contact: "Contacto",
					body: "Mensaje",
					sendAt: "Fecha programada",
					sentAt: "Fecha de envío",
					status: "Estado",
					actions: "Acciones",
				},
				buttons: {
					add: "Nueva cita",
				},
				toasts: {
					deleted: "Citación eliminada con éxito.",
				},
			},
			tags: {
				title: "Etiquetas",
				confirmationModal: {
					deleteTitle: "¿Estás seguro de que deseas eliminar esta etiqueta?",
					deleteMessage: "Esta acción no se puede revertir.",
				},
				table: {
					name: "Nombre",
					color: "Color",
					tickets: "Registros de etiquetas",
					actions: "Acciones",
				},
				buttons: {
					add: "Nueva etiqueta",
				},
				toasts: {
					deleted: "Etiqueta eliminada correctamente.",
				},
			},
			settings: {
				success: "La configuración se guardó correctamente.",
				title: "Configuración",
				settings: {
					userCreation: {
						name: "Creación de usuarios",
						options: {
							enabled: "Activado",
							disabled: "Desactivado",
						},
					},
				},
			},
			messagesList: {
				header: {
					assignedTo: "Atribuido a:",
					buttons: {
						return: "Retornar",
						resolve: "Resolver",
						reopen: "Reabrir",
						accept: "Aceptar",
					},
				},
			},
			messagesInput: {
				placeholderOpen: "Introduce un mensaje",
				placeholderClosed:
					"Vuelva a abrir o acepte este ticket para enviar un mensaje.",
				signMessage: "Suscribir",
			},
			contactDrawer: {
				header: "Datos de contacto",
				buttons: {
					edit: "Editar contacto",
				},
				extraInfo: "Otra información",
			},
			fileModal: {
				title: {
					add: "Agregar lista de archivos",
					edit: "Editar lista de archivos",
				},
				buttons: {
					okAdd: "Agregar",
					okEdit: "Editar",
					cancel: "Cancelar",
					fileOptions: "Agregar archivo",
				},
				form: {
					name: "Nombre de la lista de archivos",
					message: "Detalles de la lista",
					fileOptions: "Lista de archivos",
					extraName: "Mensaje para enviar con archivo",
					extraValue: "Valor de la opción",
				},
				success: "¡La lista de archivos se guardó correctamente!",
			},
			ticketOptionsMenu: {
				schedule: "Cita",
				delete: "Eliminar",
				transfer: "Transferir",
				registerAppointment: "Notas de contacto",
				appointmentsModal: {
					title: "Notas de contacto",
					textarea: "Observación",
					placeholder: "Ingrese aquí la información que desea registrar",
				},
				confirmationModal: {
					title: "Eliminar el ticket del contacto",
					message:
						"¡Atención! Se perderán todos los mensajes relacionados con el ticket.",
				},
				buttons: {
					delete: "Eliminar",
					cancel: "Cancelar",
				},
			},
			confirmationModal: {
				buttons: {
					confirm: "Ok",
					cancel: "Cancelar",
				},
			},
			messageOptionsMenu: {
				delete: "Eliminar",
				reply: "Responder",
				confirmationModal: {
					title: "¿Eliminar mensaje?",
					message: "Esta acción no se puede revertir.",
				},
			},
			financeiro: {
				Detalhes: "Detalles",
				Valor: "Valor",
				DataVenc: "Data Venc",
				Status: "Estado",
				Ação: "Acción ",
				PAGAR: "PAGAR",
				PAGO: "PAGO",
			},
			messagesAPI: {
				messagesEnvioDoc: "Documentación para el envío de mensajes",
				metodosEnvio: "Métodos de Envio",
				mensagenText: "Mensajes de Texto",
				mensagenMedia: "Mensajes de Medios",
				Instruções: "Instrucciones",
				ObservaçõesImportantes: "Observaciones importantes",
				ObservaçõesImportantes1: "Antes de enviar mensajes, es necesario registrar el token vinculado a la conexión que enviará los mensajes.",
				ObservaçõesImportantes2: "Para registrar el token, accede al menú 'Conexiones', haz clic en el botón de editar de la conexión e introduce el token en el campo correspondiente.",
				ObservaçõesImportantes3: "El número para envío no debe tener máscara o caracteres especiales y debe estar compuesto por:",
				codigoPais: "Código del país",
				ddd: "Código de área (DDD)",
				numero: "Número",
				mensagenText: "Mensajes de Texto",
				ObservaçõesImportantes4: "A continuación, se muestra la lista de información necesaria para el envío de mensajes de texto:",
				Endpoint: "Punto de acceso (Endpoint)",
				metodo: "Método",
				headers: "Encabezados (Headers)",
				body: "Cuerpo (Body)",
				teste: "Prueba de Envío",
				mensagenMedia: "Mensajes de Medios",
				ObservaçõesImportantes4: "A continuación, se muestra la lista de información necesaria para el envío de mensajes de medios:",
				FormData: "FormData",
				number: "Número",
				medias: "Medios",
				arquivo: "Archivo",
				testeEnvio: "Prueba de Envío",
				tokenCadastrado: "token registrado",
			},
			kanban: {
				emAberto: "Abierto",
			},
			tarefa: {
				tarefa: "Nueva tarea",
			},
			chatInternal: {
				nova: "Nuevo",
				conversa: "Conversación",
				filtroUsers: "Filtrar por Usuarios",
				excluirConversa: "Eliminar conversación",
				alertExcluirConversa: "Esta acción no se puede revertir, ¿confirmar?"
			},

			// dashboard: {
			// 	dataVencimiento: "Fecha de Vencimiento",
			// 	dataInicial: "Fecha Inicial",
			// 	dataFinal: "Fecha Final",
			// 	tipoDeFiltro: "Tipo de Filtro",
			// 	filtroPorData: "Filtro por Fecha",
			// 	filtroPorPeriodo: "Filtro por Período",
			// 	seleecione: "Seleccione el período deseado",
			// 	filtrar: "Filtrar",
			// 	pendentes: "Atd. Pendientes",
			// 	acontecendo: "Atd. En Curso",
			// 	realizados: "Atd. Realizados",
			// 	leads: "Leads",
			// 	atendimento: "T.M Atención",
			// 	espera: "T.M Espera",
			// 	periodo: "Período",
			// 	nenhumSelecionado: "Ninguno seleccionado",
			// 	seleccionePeriodoDesajado: "Seleccione el período deseado",
			// 	nome: "Nombre",
			// 	Avaliações: "Evaluaciones",
			// 	status: "Estado (Actual)"
			// },

			Opções: {
				atualizando: "Atualizando",
				opções: "Opciones",
				empresas: "Empresas",
				planos: "Planes",
				ajuda: "Ayuda",
				exito: "Operación actualizada con éxito.",
				avaliações: "Evaluaciones",
				habilitadas: "Habilitadas",
				desabilitadas: "Desabilitadas",
				desabilitado: "Desabilitado",
				habilitado: "Habilitado",
				gerenciamiento: "Gerenciamento de Expediente",
				gerenciamientofila: "Gerenciamento por Fila",
				ignorarMesageGrupos: "Ignorar Mensajes de Grupos",
				desactivado: "Desactivado",
				activado: "Activado",
				chamada: "Aceptar Llamadas",
				nãoAceita: "No aceptar",
				aceitar: "Aceptar",
				tipoChatbot: "Tipo de Chatbot",
				texto: "Texto",
				Enviarsaudaçãoaoaceitarticket: "Enviar saludo al aceptar el ticket",
				enviarMensagemTransferenciadeFilaAgente: "Enviar mensaje de transferencia de fila/agente",
				integrqacoes: "Integraciones",
				tituloNoDefinido: "Título no definido",

			},
			modals: {
				muitoCorto: "Muy corto!",
				muitoLongo: "Muy largo!",
				obrigatorio: "Obligatorio",
				descrevaTreinamentoDoIA: "Describa el entrenamiento para Inteligencia Artificial",
				informeoModoVoz: "Indique el modo para Voz",
				informeMaxTokens: "Indique el número máximo de tokens",
				informeTemperatura: "Indique la temperatura",
				informeApiKey: "Indique la API Key",
				informeFila: "Indique la fila",
				informeMaxMensagnes: "Indique el número máximo de mensajes",
				informeSetor: "Indique el sector",
			},
			checkoutFormModel: {
				label: {
					nomeCompleto: "Nombre completo*",
					lastName: "Apellido*",
					endereco: "Dirección*",
					cidade: "Ciudad*",
					estado: "Estado*",
					pais: "País*",
					useAddressForPaymentDetails: "Usar esta dirección para detalles de pago",
					useInvoiceId: "Usar este ID de factura*",
					NameCard: "Nombre en la tarjeta*",
					cardNumber: "Número de tarjeta*",
					ExpireDate: "Fecha de expiración*"
				},
				requiredErrorMsg: {
					nome: "El nombre es obligatorio.",
					lastName: "El apellido es obligatorio.",
					endreco: "La dirección es obligatoria.",
					cidade: "La ciudad es obligatoria.",
					state: "El estado es obligatorio.",
					zipcode: "El código postal es obligatorio.",
					pais: "El país es obligatorio.",
					NameCard: "El nombre en la tarjeta es obligatorio.",
					cardNumber: "El número de tarjeta es obligatorio.",
					ExpireDate: "La fecha de expiración es obligatoria.",
					cvv: "El CVV es obligatorio."
				},
				invalidErrorMsg: {
					zipcode: "Formato de código postal inválido.",
					cardNumber: "El número de tarjeta no es válido (por ejemplo, 4111111111111).",
					ExpireDate: "La fecha de expiración no es válida.",
					cvv: "El CVV no es válido (por ejemplo, 357)."
				}
			},
			planos: {
				nome: "Nombre",
				valor: "Valor",
				usuarios: "Usuarios",
				conexões: "Conexiones",
				filas: "Filas",
				limpar: "Limpiar",
				excluir: "Excluir"
			},

			ayuda: {
				titulo: "Título",
				codigoVideo: "Código del Video",
				descricao: "Descripción",
				limpiar: "Limpiar",
				excluir: "Excluir",
				errCargarListas: "No fue posible cargar la lista de registros",
				sucessoExitoso: "¡Operación realizada con éxito!",
				errNoPosibleSucesso: "No fue posible realizar la operación. Verifique si ya existe un ayuda con el mismo nombre o si los campos fueron llenados correctamente"
			},
			CheckoutSuccess: {
				total: "TOTAL",
				copiado: "Copiado",
				copiarQR: "Copiar código QR",
				finalizar: "Para finalizar, basta realizar el pago escaneando o pegando el código Pix arriba:"
			},
			contacnotes: {
				excluirRegistro: "Eliminar Registro",
				cancelar: "Cancelar",
				observacionSucesso: "¡Observación añadida con éxito!",
				observacionExcluida: "¡Observación eliminada con éxito!"
			},
			etc: {
				obrigatorio: "Obligatorio",
				adicionarVariable: "Agregar Variable",
				exportarContato: "EXPORTAR CONTACTOS",
				salvarconfiguracioes: "Guardar Configuraciones",
				salvarParaRegistrar: "Haga clic en guardar para registrar los cambios.",
				salvarRegistrarAlteracioes: "Haga clic en guardar para registrar los cambios.",
				helperText: "Utiliza variables como {nombre}, {número}, {correo} o define variables personalizadas",
				seleccioneFila: "Seleccione una fila",
				seleccioneConexao: "Seleccione una conexión",
				assinaturaSucesso: "¡Suscripción realizada con éxito!, esperando la realización del pago",
				voltar: "VOLVER",
				licencarenovada: "Su licencia ha sido renovada hasta",
				errComppanie: "No fue posible realizar la operación. Verifique si ya existe una empresa con el mismo nombre o si los campos han sido llenados correctamente",
				errComppanieOperacao: "No fue posible realizar la operación",
				naoCargarListas: "No fue posible cargar la lista de registros",
				operacaoSucesso: "¡Operación realizada con éxito!",
				errOperacao: "No fue posible realizar la operación",
				noFoiPosbleOperacao: "No fue posible realizar la operación. Verifique si ya existe un plan con el mismo nombre o si los campos han sido llenados correctamente",
				acessoNoaPermitido: "Acceso no permitido",
				ticketDeleted: "Ticket eliminado con éxito.",
				errorOccurred: "Ocurrió un error.",
				venceAsinatura: "Su suscripción vence en",
				oppdVenceAsinatrua: "¡Ups! Su suscripción vence en",
				oppdVenceAsinatrua2: "días. Por favor, renueve su suscripción para continuar usando el sistema.",
				contaOutroComputador: "Su cuenta ha sido accedida desde otro computador.",
				redireccion: "¡Esta empresa no tiene permiso para acceder a esta página! Le estamos redirigiendo.",
				parametrizeFiltro: "Parametriza el filtro",
				ticketRemovido: "¡Etiqueta de ticket eliminada!",
				ticketAdicionado: "¡Etiqueta de ticket añadida con éxito!",
				mensagemExito: "¡Mensaje enviado con éxito!",
				horariosAtualizados: "Horarios actualizados con éxito.",
				tituloNoDefinido: "Título no definido",
				atalho: "Atajo",
				mensagem: "Mensaje",
				errEnvio: "Error de Envio",
				ações: "Acciones",
				mensagemPeloContato: "Este mensaje fue eliminado por el contacto",
				nenhumRegistro: "Ningún registro",
				nao: "No",
				sim: "Si",
				Tags: "Etiquetas",
				atalhos: "Atajos",
				conteudo: "Contenido",
				porFavorPreenchaTitulo: "Por favor, completa el título de la conversación.",
				SlecionePeloMenosUsuario: "Por favor, selecciona al menos un usuario.",
				Gerência:"Gerencia",

			},

			backendErrors: {
				ERR_NO_OTHER_WHATSAPP: "Debe haber al menos un WhatsApp predeterminado.",
				ERR_NO_DEF_WAPP_FOUND:
					"No se encontró ningún WhatsApp predeterminado. Consulta la página de conexiones.",
				ERR_WAPP_NOT_INITIALIZED:
					"Esta sesión de WhatsApp no ​​se ha inicializado. Consulta la página de conexiones.",
				ERR_WAPP_CHECK_CONTACT:
					"No se puede verificar el contacto de WhatsApp. Consulta la página de conexiones.",
				ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
				ERR_WAPP_DOWNLOAD_MEDIA:
					"No se pueden descargar medios de WhatsApp. Consulta la página de conexiones.",
				ERR_INVALID_CREDENTIALS:
					"Error de autenticación. Por favor inténtalo de nuevo.",
				ERR_SENDING_WAPP_MSG:
					"Error al enviar mensaje de WhatsApp. Consulta la página de conexiones.",
				ERR_DELETE_WAPP_MSG: "No se puede eliminar el mensaje de WhatsApp.",
				ERR_OTHER_OPEN_TICKET: "Ya hay un ticket abierto para este contacto.",
				ERR_SESSION_EXPIRED: "La sesión expiró. Por favor entra.",
				ERR_USER_CREATION_DISABLED:
					"El administrador ha desactivado la creación de usuarios.",
				ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
				ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
				ERR_NO_SETTING_FOUND: "No se encontró ninguna configuración con este ID.",
				ERR_NO_CONTACT_FOUND: "No se encontraron contactos con este ID.",
				ERR_NO_TICKET_FOUND: "No se encontraron boletos con esta identificación.",
				ERR_NO_USER_FOUND: "No se encontraron usuarios con este ID.",
				ERR_NO_WAPP_FOUND: "No se encontró ningún WhatsApp con este ID.",
				ERR_CREATING_MESSAGE: "Error al crear mensaje en la base de datos.",
				ERR_CREATING_TICKET: "Error al crear ticket en la base de datos.",
				ERR_FETCH_WAPP_MSG:
					"Error al buscar el mensaje en WhatsApp, quizás sea demasiado antiguo.",
				ERR_QUEUE_COLOR_ALREADY_EXISTS:
					"Este color ya está en uso, elige otro.",
				ERR_WAPP_GREETING_REQUIRED:
					"El mensaje de saludo es obligatorio cuando hay más de una fila.",
			},
		},
	},
};

export { messages };